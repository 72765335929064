import defaultAttributes from '../defaultAttributes';

const TerminalSquare = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm7 11 2-2-2-2' }],
    ['path', { d: 'M11 13h4' }],
    ['rect', { x: '3', y: '3', width: '18', height: '18', rx: '2', ry: '2' }],
  ],
];

export default TerminalSquare;
