import defaultAttributes from '../defaultAttributes';

const MoreVertical = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '1' }],
    ['circle', { cx: '12', cy: '5', r: '1' }],
    ['circle', { cx: '12', cy: '19', r: '1' }],
  ],
];

export default MoreVertical;
