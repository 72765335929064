import defaultAttributes from '../defaultAttributes';

const AlarmPlus = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 21a8 8 0 1 0 0-16 8 8 0 0 0 0 16z' }],
    ['path', { d: 'M5 3 2 6' }],
    ['path', { d: 'm22 6-3-3' }],
    ['path', { d: 'm6 19-2 2' }],
    ['path', { d: 'm18 19 2 2' }],
    ['path', { d: 'M12 10v6' }],
    ['path', { d: 'M9 13h6' }],
  ],
];

export default AlarmPlus;
