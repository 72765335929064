import defaultAttributes from '../defaultAttributes';

const Armchair = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M19 9V6a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v3' }],
    [
      'path',
      {
        d:
          'M3 11v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a2 2 0 0 0-4 0v2H7v-2a2 2 0 0 0-4 0Z',
      },
    ],
    ['path', { d: 'M5 18v2' }],
    ['path', { d: 'M19 18v2' }],
  ],
];

export default Armchair;
