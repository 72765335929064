import defaultAttributes from '../defaultAttributes';

const X = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '18', y1: '6', x2: '6', y2: '18' }],
    ['line', { x1: '6', y1: '6', x2: '18', y2: '18' }],
  ],
];

export default X;
