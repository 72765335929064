import defaultAttributes from '../defaultAttributes';

const HeartOff = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M4.12 4.107a5.4 5.4 0 0 0-.538.473C1.46 6.7 1.33 10.28 4 13l8 8 4.5-4.5',
      },
    ],
    [
      'path',
      {
        d:
          'M19.328 13.672 20 13c2.67-2.72 2.54-6.3.42-8.42a5.4 5.4 0 0 0-7.65 0l-.77.78-.77-.78a5.4 5.4 0 0 0-2.386-1.393',
      },
    ],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default HeartOff;
