import defaultAttributes from '../defaultAttributes';

const FolderPlus = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z',
      },
    ],
    ['line', { x1: '12', y1: '10', x2: '12', y2: '16' }],
    ['line', { x1: '9', y1: '13', x2: '15', y2: '13' }],
  ],
];

export default FolderPlus;
