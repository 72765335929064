import defaultAttributes from '../defaultAttributes';

const Fan = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M10.827 16.379a6.082 6.082 0 0 1-8.618-7.002l5.412 1.45a6.082 6.082 0 0 1 7.002-8.618l-1.45 5.412a6.082 6.082 0 0 1 8.618 7.002l-5.412-1.45a6.082 6.082 0 0 1-7.002 8.618l1.45-5.412Z',
      },
    ],
    ['path', { d: 'M12 12v.01' }],
  ],
];

export default Fan;
