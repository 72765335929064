import defaultAttributes from '../defaultAttributes';

const MapPinOff = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M5.43 5.43A8.06 8.06 0 0 0 4 10c0 6 8 12 8 12a29.94 29.94 0 0 0 5-5',
      },
    ],
    [
      'path',
      {
        d:
          'M19.18 13.52A8.66 8.66 0 0 0 20 10a8 8 0 0 0-8-8 7.88 7.88 0 0 0-3.52.82',
      },
    ],
    [
      'path',
      {
        d:
          'M9.13 9.13A2.78 2.78 0 0 0 9 10a3 3 0 0 0 3 3 2.78 2.78 0 0 0 .87-.13',
      },
    ],
    ['path', { d: 'M14.9 9.25a3 3 0 0 0-2.15-2.16' }],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default MapPinOff;
