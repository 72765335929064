import defaultAttributes from '../defaultAttributes';

const LayoutDashboard = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '3', width: '7', height: '9' }],
    ['rect', { x: '14', y: '3', width: '7', height: '5' }],
    ['rect', { x: '14', y: '12', width: '7', height: '9' }],
    ['rect', { x: '3', y: '16', width: '7', height: '5' }],
  ],
];

export default LayoutDashboard;
