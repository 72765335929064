import defaultAttributes from '../defaultAttributes';

const Paintbrush2 = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M14 19.9V16h3a2 2 0 0 0 2-2v-2H5v2c0 1.1.9 2 2 2h3v3.9a2 2 0 1 0 4 0Z',
      },
    ],
    ['path', { d: 'M6 12V2h12v10' }],
    ['path', { d: 'M14 2v4' }],
    ['path', { d: 'M10 2v2' }],
  ],
];

export default Paintbrush2;
