import defaultAttributes from '../defaultAttributes';

const ShieldOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18' }],
    [
      'path',
      { d: 'M4.73 4.73 4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38' },
    ],
    ['line', { x1: '2', y1: '2', x2: '22', y2: '22' }],
  ],
];

export default ShieldOff;
