import defaultAttributes from '../defaultAttributes';

const Gem = [
  'svg',
  defaultAttributes,
  [
    ['polygon', { points: '6 3 18 3 22 9 12 22 2 9' }],
    ['path', { d: 'm12 22 4-13-3-6' }],
    ['path', { d: 'M12 22 8 9l3-6' }],
    ['path', { d: 'M2 9h20' }],
  ],
];

export default Gem;
