import defaultAttributes from '../defaultAttributes';

const Tv2 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M7 21h10' }],
    ['rect', { x: '2', y: '3', width: '20', height: '14', rx: '2' }],
  ],
];

export default Tv2;
