import defaultAttributes from '../defaultAttributes';

const FormInput = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '2', y: '6', width: '20', height: '12', rx: '2' }],
    ['path', { d: 'M12 12h.01' }],
    ['path', { d: 'M17 12h.01' }],
    ['path', { d: 'M7 12h.01' }],
  ],
];

export default FormInput;
