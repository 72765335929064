import defaultAttributes from '../defaultAttributes';

const Tent = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M19 20 10 4' }],
    ['path', { d: 'm5 20 9-16' }],
    ['path', { d: 'M3 20h18' }],
    ['path', { d: 'm12 15-3 5' }],
    ['path', { d: 'm12 15 3 5' }],
  ],
];

export default Tent;
