import defaultAttributes from '../defaultAttributes';

const RotateCw = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 2v6h-6' }],
    ['path', { d: 'M21 13a9 9 0 1 1-3-7.7L21 8' }],
  ],
];

export default RotateCw;
