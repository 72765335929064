import defaultAttributes from '../defaultAttributes';

const Mic2 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12' }],
    ['circle', { cx: '17', cy: '7', r: '5' }],
  ],
];

export default Mic2;
