import defaultAttributes from '../defaultAttributes';

const FilePlus = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      },
    ],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['line', { x1: '12', y1: '18', x2: '12', y2: '12' }],
    ['line', { x1: '9', y1: '15', x2: '15', y2: '15' }],
  ],
];

export default FilePlus;
