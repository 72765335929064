import defaultAttributes from '../defaultAttributes';

const Wind = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M17.7 7.7a2.5 2.5 0 1 1 1.8 4.3H2' }],
    ['path', { d: 'M9.6 4.6A2 2 0 1 1 11 8H2' }],
    ['path', { d: 'M12.6 19.4A2 2 0 1 0 14 16H2' }],
  ],
];

export default Wind;
