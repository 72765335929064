import defaultAttributes from '../defaultAttributes';

const CreditCard = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '2', y: '5', width: '20', height: '14', rx: '2' }],
    ['line', { x1: '2', y1: '10', x2: '22', y2: '10' }],
  ],
];

export default CreditCard;
