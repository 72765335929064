import defaultAttributes from '../defaultAttributes';

const Sofa = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 9V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v3' }],
    [
      'path',
      {
        d:
          'M2 11v5a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-5a2 2 0 0 0-4 0v2H6v-2a2 2 0 0 0-4 0Z',
      },
    ],
    ['path', { d: 'M4 18v2' }],
    ['path', { d: 'M20 18v2' }],
    ['path', { d: 'M12 4v9' }],
  ],
];

export default Sofa;
