import defaultAttributes from '../defaultAttributes';

const ToggleLeft = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '1', y: '5', width: '22', height: '14', rx: '7', ry: '7' }],
    ['circle', { cx: '8', cy: '12', r: '3' }],
  ],
];

export default ToggleLeft;
