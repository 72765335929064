import defaultAttributes from '../defaultAttributes';

const PaintBucket = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'm19 11-8-8-8.6 8.6a2 2 0 0 0 0 2.8l5.2 5.2c.8.8 2 .8 2.8 0L19 11Z',
      },
    ],
    ['path', { d: 'm5 2 5 5' }],
    ['path', { d: 'M2 13h15' }],
    ['path', { d: 'M22 20a2 2 0 1 1-4 0c0-1.6 1.7-2.4 2-4 .3 1.6 2 2.4 2 4Z' }],
  ],
];

export default PaintBucket;
