import defaultAttributes from '../defaultAttributes';

const Factory = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M2 20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8l-7 5V8l-7 5V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z',
      },
    ],
    ['path', { d: 'M17 18h1' }],
    ['path', { d: 'M12 18h1' }],
    ['path', { d: 'M7 18h1' }],
  ],
];

export default Factory;
