import defaultAttributes from '../defaultAttributes';

const Command = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z',
      },
    ],
  ],
];

export default Command;
