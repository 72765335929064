import defaultAttributes from '../defaultAttributes';

const FileBadge = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 7V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2h-6' }],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z' }],
    ['path', { d: 'M7 16.5 8 22l-3-1-3 1 1-5.5' }],
  ],
];

export default FileBadge;
