import defaultAttributes from '../defaultAttributes';

const Disc = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '12', r: '10' }],
    ['circle', { cx: '12', cy: '12', r: '3' }],
  ],
];

export default Disc;
