import defaultAttributes from '../defaultAttributes';

const BarChart3 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 3v18h18' }],
    ['path', { d: 'M18 17V9' }],
    ['path', { d: 'M13 17V5' }],
    ['path', { d: 'M8 17v-3' }],
  ],
];

export default BarChart3;
