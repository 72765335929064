import defaultAttributes from '../defaultAttributes';

const FolderHeart = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M11 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v1.5',
      },
    ],
    [
      'path',
      {
        d:
          'M21.29 13.7a2.43 2.43 0 0 0-2.65-.52c-.3.12-.57.3-.8.53l-.34.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L17.5 21l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
      },
    ],
  ],
];

export default FolderHeart;
