import defaultAttributes from '../defaultAttributes';

const FileDigit = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4' }],
    ['polyline', { points: '14 2 14 8 20 8' }],
    ['path', { d: 'M10 12h2v6' }],
    ['rect', { x: '2', y: '12', width: '4', height: '6' }],
    ['path', { d: 'M10 18h4' }],
  ],
];

export default FileDigit;
