import defaultAttributes from '../defaultAttributes';

const AirVent = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M6 12H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2',
      },
    ],
    ['path', { d: 'M6 8h12' }],
    [
      'path',
      { d: 'M18.3 17.7a2.5 2.5 0 0 1-3.16 3.83 2.53 2.53 0 0 1-1.14-2V12' },
    ],
    ['path', { d: 'M6.6 15.6A2 2 0 1 0 10 17v-5' }],
  ],
];

export default AirVent;
