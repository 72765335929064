import defaultAttributes from '../defaultAttributes';

const AlarmClockOff = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M6.87 6.87a8 8 0 1 0 11.26 11.26' }],
    [
      'path',
      {
        d:
          'M19.9 14.25A7.44 7.44 0 0 0 20 13a8 8 0 0 0-8-8 7.44 7.44 0 0 0-1.25.1',
      },
    ],
    ['path', { d: 'm22 6-3-3' }],
    ['path', { d: 'm6 19-2 2' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M4 4 2 6' }],
  ],
];

export default AlarmClockOff;
