import defaultAttributes from '../defaultAttributes';

const FileWarning = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      },
    ],
    ['path', { d: 'M12 9v4' }],
    ['path', { d: 'M12 17h.01' }],
  ],
];

export default FileWarning;
