import defaultAttributes from '../defaultAttributes';

const Code = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '16 18 22 12 16 6' }],
    ['polyline', { points: '8 6 2 12 8 18' }],
  ],
];

export default Code;
