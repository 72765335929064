import defaultAttributes from '../defaultAttributes';

const TrendingDown = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '22 17 13.5 8.5 8.5 13.5 2 7' }],
    ['polyline', { points: '16 17 22 17 22 11' }],
  ],
];

export default TrendingDown;
