import defaultAttributes from '../defaultAttributes';

const Pencil = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '18', y1: '2', x2: '22', y2: '6' }],
    ['path', { d: 'M7.5 20.5 19 9l-4-4L3.5 16.5 2 22z' }],
  ],
];

export default Pencil;
