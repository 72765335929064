import defaultAttributes from '../defaultAttributes';

const Search = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '11', cy: '11', r: '8' }],
    ['line', { x1: '21', y1: '21', x2: '16.65', y2: '16.65' }],
  ],
];

export default Search;
