import defaultAttributes from '../defaultAttributes';

const FolderEdit = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M8.42 10.61a2.1 2.1 0 1 1 2.97 2.97L5.95 19 2 20l.99-3.95 5.43-5.44Z',
      },
    ],
    [
      'path',
      {
        d:
          'M2 11.5V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-9.5',
      },
    ],
  ],
];

export default FolderEdit;
