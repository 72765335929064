import defaultAttributes from '../defaultAttributes';

const Settings2 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M20 7h-9' }],
    ['path', { d: 'M14 17H5' }],
    ['circle', { cx: '17', cy: '17', r: '3' }],
    ['circle', { cx: '7', cy: '7', r: '3' }],
  ],
];

export default Settings2;
