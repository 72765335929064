import defaultAttributes from '../defaultAttributes';

const History = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M3 3v5h5' }],
    ['path', { d: 'M3.05 13A9 9 0 1 0 6 5.3L3 8' }],
    ['path', { d: 'M12 7v5l4 2' }],
  ],
];

export default History;
