import defaultAttributes from '../defaultAttributes';

const ChefHat = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M6 13.87A4 4 0 0 1 7.41 6a5.11 5.11 0 0 1 1.05-1.54 5 5 0 0 1 7.08 0A5.11 5.11 0 0 1 16.59 6 4 4 0 0 1 18 13.87V21H6Z',
      },
    ],
    ['line', { x1: '6', y1: '17', x2: '18', y2: '17' }],
  ],
];

export default ChefHat;
