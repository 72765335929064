import defaultAttributes from '../defaultAttributes';

const GlassWater = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M15.2 22H8.8a2 2 0 0 1-2-1.79L5 3h14l-1.81 17.21A2 2 0 0 1 15.2 22Z',
      },
    ],
    ['path', { d: 'M6 12a5 5 0 0 1 6 0 5 5 0 0 0 6 0' }],
  ],
];

export default GlassWater;
