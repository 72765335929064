import defaultAttributes from '../defaultAttributes';

const Tornado = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 4H3' }],
    ['path', { d: 'M18 8H6' }],
    ['path', { d: 'M19 12H9' }],
    ['path', { d: 'M16 16h-6' }],
    ['path', { d: 'M11 20H9' }],
  ],
];

export default Tornado;
