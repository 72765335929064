import defaultAttributes from '../defaultAttributes';

const Globe2 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M15 21v-4a2 2 0 0 1 2-2h4' }],
    [
      'path',
      {
        d: 'M7 4v2a3 3 0 0 0 3 2h0a2 2 0 0 1 2 2 2 2 0 0 0 4 0 2 2 0 0 1 2-2h3',
      },
    ],
    ['path', { d: 'M3 11h2a2 2 0 0 1 2 2v1a2 2 0 0 0 2 2 2 2 0 0 1 2 2v4' }],
    ['circle', { cx: '12', cy: '12', r: '10' }],
  ],
];

export default Globe2;
