import defaultAttributes from '../defaultAttributes';

const HardHat = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z',
      },
    ],
    ['path', { d: 'M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5' }],
    ['path', { d: 'M4 15v-3a6 6 0 0 1 6-6h0' }],
    ['path', { d: 'M14 6h0a6 6 0 0 1 6 6v3' }],
  ],
];

export default HardHat;
