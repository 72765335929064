import defaultAttributes from '../defaultAttributes';

const FlaskConical = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M10 2v8L4.72 20.55a1 1 0 0 0 .9 1.45h12.76a1 1 0 0 0 .9-1.45L14 10V2',
      },
    ],
    ['path', { d: 'M8.5 2h7' }],
    ['path', { d: 'M7 16h10' }],
  ],
];

export default FlaskConical;
