import defaultAttributes from '../defaultAttributes';

const AlignStartHorizontal = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '4', y: '6', width: '6', height: '16', rx: '2' }],
    ['rect', { x: '14', y: '6', width: '6', height: '9', rx: '2' }],
    ['path', { d: 'M22 2H2' }],
  ],
];

export default AlignStartHorizontal;
