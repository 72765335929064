import defaultAttributes from '../defaultAttributes';

const Equal = [
  'svg',
  defaultAttributes,
  [
    ['line', { x1: '5', y1: '9', x2: '19', y2: '9' }],
    ['line', { x1: '5', y1: '15', x2: '19', y2: '15' }],
  ],
];

export default Equal;
