import defaultAttributes from '../defaultAttributes';

const Brush = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'm9.06 11.9 8.07-8.06a2.85 2.85 0 1 1 4.03 4.03l-8.06 8.08' },
    ],
    [
      'path',
      {
        d:
          'M7.07 14.94c-1.66 0-3 1.35-3 3.02 0 1.33-2.5 1.52-2 2.02 1.08 1.1 2.49 2.02 4 2.02 2.2 0 4-1.8 4-4.04a3.01 3.01 0 0 0-3-3.02z',
      },
    ],
  ],
];

export default Brush;
