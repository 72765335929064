import defaultAttributes from '../defaultAttributes';

const FileHeart = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 6V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4' }],
    ['polyline', { points: '14 2 14 8 20 8' }],
    [
      'path',
      {
        d:
          'M10.29 10.7a2.43 2.43 0 0 0-2.66-.52c-.29.12-.56.3-.78.53l-.35.34-.35-.34a2.43 2.43 0 0 0-2.65-.53c-.3.12-.56.3-.79.53-.95.94-1 2.53.2 3.74L6.5 18l3.6-3.55c1.2-1.21 1.14-2.8.19-3.74Z',
      },
    ],
  ],
];

export default FileHeart;
