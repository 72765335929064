import defaultAttributes from '../defaultAttributes';

const FileX2 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4' }],
    ['path', { d: 'M14 2v6h6' }],
    ['path', { d: 'm3 12.5 5 5' }],
    ['path', { d: 'm8 12.5-5 5' }],
  ],
];

export default FileX2;
