import defaultAttributes from '../defaultAttributes';

const Library = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm16 6 4 14' }],
    ['path', { d: 'M12 6v14' }],
    ['path', { d: 'M8 8v12' }],
    ['path', { d: 'M4 4v16' }],
  ],
];

export default Library;
