import defaultAttributes from '../defaultAttributes';

const Usb = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '4', cy: '20', r: '1' }],
    ['circle', { cx: '10', cy: '7', r: '1' }],
    ['path', { d: 'M4 20 19 5' }],
    ['path', { d: 'm21 3-3 1 2 2 1-3Z' }],
    ['path', { d: 'm10 7-5 5 2 5' }],
    ['path', { d: 'm10 14 5 2 4-4' }],
    ['path', { d: 'm18 12 1-1 1 1-1 1-1-1Z' }],
  ],
];

export default Usb;
