import defaultAttributes from '../defaultAttributes';

const Award = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '12', cy: '8', r: '6' }],
    ['path', { d: 'M15.477 12.89 17 22l-5-3-5 3 1.523-9.11' }],
  ],
];

export default Award;
