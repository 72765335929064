import defaultAttributes from '../defaultAttributes';

const LayoutGrid = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '3', width: '7', height: '7' }],
    ['rect', { x: '14', y: '3', width: '7', height: '7' }],
    ['rect', { x: '14', y: '14', width: '7', height: '7' }],
    ['rect', { x: '3', y: '14', width: '7', height: '7' }],
  ],
];

export default LayoutGrid;
