import defaultAttributes from '../defaultAttributes';

const Pause = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '6', y: '4', width: '4', height: '16' }],
    ['rect', { x: '14', y: '4', width: '4', height: '16' }],
  ],
];

export default Pause;
