import defaultAttributes from '../defaultAttributes';

const SidebarOpen = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '3', width: '18', height: '18', rx: '2', ry: '2' }],
    ['path', { d: 'M9 3v18' }],
    ['path', { d: 'm14 9 3 3-3 3' }],
  ],
];

export default SidebarOpen;
