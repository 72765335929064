import defaultAttributes from '../defaultAttributes';

const Dice2 = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '3', y: '3', width: '18', height: '18', rx: '2', ry: '2' }],
    ['path', { d: 'M15 9h.01' }],
    ['path', { d: 'M9 15h.01' }],
  ],
];

export default Dice2;
