import defaultAttributes from '../defaultAttributes';

const ChevronsRight = [
  'svg',
  defaultAttributes,
  [
    ['polyline', { points: '13 17 18 12 13 7' }],
    ['polyline', { points: '6 17 11 12 6 7' }],
  ],
];

export default ChevronsRight;
