import defaultAttributes from '../defaultAttributes';

const BellPlus = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      { d: 'M18.387 12C19.198 15.799 21 17 21 17H3s3-2 3-9a6 6 0 0 1 7-5.916' },
    ],
    ['path', { d: 'M13.73 21a2 2 0 0 1-3.46 0' }],
    ['path', { d: 'M18 2v6' }],
    ['path', { d: 'M21 5h-6' }],
  ],
];

export default BellPlus;
