import defaultAttributes from '../defaultAttributes';

const BookOpen = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z' }],
    ['path', { d: 'M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z' }],
  ],
];

export default BookOpen;
