import defaultAttributes from '../defaultAttributes';

const SwissFranc = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 21V3h8' }],
    ['path', { d: 'M6 16h9' }],
    ['path', { d: 'M10 9.5h7' }],
  ],
];

export default SwissFranc;
