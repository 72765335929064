import defaultAttributes from '../defaultAttributes';

const StretchVertical = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '4', y: '2', width: '6', height: '20', rx: '2' }],
    ['rect', { x: '14', y: '2', width: '6', height: '20', rx: '2' }],
  ],
];

export default StretchVertical;
