import defaultAttributes from '../defaultAttributes';

const Edit3 = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 20h9' }],
    ['path', { d: 'M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z' }],
  ],
];

export default Edit3;
