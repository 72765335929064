import defaultAttributes from '../defaultAttributes';

const Wifi = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M5 13a10 10 0 0 1 14 0' }],
    ['path', { d: 'M8.5 16.5a5 5 0 0 1 7 0' }],
    ['path', { d: 'M2 8.82a15 15 0 0 1 20 0' }],
    ['line', { x1: '12', y1: '20', x2: '12.01', y2: '20' }],
  ],
];

export default Wifi;
