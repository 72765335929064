import defaultAttributes from '../defaultAttributes';

const Smartphone = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '5', y: '2', width: '14', height: '20', rx: '2', ry: '2' }],
    ['path', { d: 'M12 18h.01' }],
  ],
];

export default Smartphone;
