import defaultAttributes from '../defaultAttributes';

const RussianRuble = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M14 11c5.333 0 5.333-8 0-8' }],
    ['path', { d: 'M6 11h8' }],
    ['path', { d: 'M6 15h8' }],
    ['path', { d: 'M9 21V3' }],
    ['path', { d: 'M9 3h5' }],
  ],
];

export default RussianRuble;
