import defaultAttributes from '../defaultAttributes';

const Voicemail = [
  'svg',
  defaultAttributes,
  [
    ['circle', { cx: '6', cy: '12', r: '4' }],
    ['circle', { cx: '18', cy: '12', r: '4' }],
    ['line', { x1: '6', y1: '16', x2: '18', y2: '16' }],
  ],
];

export default Voicemail;
