import defaultAttributes from '../defaultAttributes';

const CloudMoon = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M13 22H7a5 5 0 1 1 4.9-6H13a3 3 0 0 1 0 6Z' }],
    [
      'path',
      {
        d:
          'M10.083 9A6.002 6.002 0 0 1 16 4a4.243 4.243 0 0 0 6 6c0 2.22-1.206 4.16-3 5.197',
      },
    ],
  ],
];

export default CloudMoon;
