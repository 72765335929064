import defaultAttributes from '../defaultAttributes';

const PackageOpen = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d:
          'M20.91 8.84 8.56 2.23a1.93 1.93 0 0 0-1.81 0L3.1 4.13a2.12 2.12 0 0 0-.05 3.69l12.22 6.93a2 2 0 0 0 1.94 0L21 12.51a2.12 2.12 0 0 0-.09-3.67Z',
      },
    ],
    [
      'path',
      {
        d:
          'm3.09 8.84 12.35-6.61a1.93 1.93 0 0 1 1.81 0l3.65 1.9a2.12 2.12 0 0 1 .1 3.69L8.73 14.75a2 2 0 0 1-1.94 0L3 12.51a2.12 2.12 0 0 1 .09-3.67Z',
      },
    ],
    ['line', { x1: '12', y1: '22', x2: '12', y2: '13' }],
    [
      'path',
      {
        d:
          'M20 13.5v3.37a2.06 2.06 0 0 1-1.11 1.83l-6 3.08a1.93 1.93 0 0 1-1.78 0l-6-3.08A2.06 2.06 0 0 1 4 16.87V13.5',
      },
    ],
  ],
];

export default PackageOpen;
