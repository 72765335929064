import defaultAttributes from '../defaultAttributes';

const BluetoothConnected = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'm7 7 10 10-5 5V2l5 5L7 17' }],
    ['line', { x1: '18', y1: '12', y2: '12', x2: '21' }],
    ['line', { x1: '3', y1: '12', y2: '12', x2: '6' }],
  ],
];

export default BluetoothConnected;
