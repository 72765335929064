import defaultAttributes from '../defaultAttributes';

const SmilePlus = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M22 11v1a10 10 0 1 1-9-10' }],
    ['path', { d: 'M8 14s1.5 2 4 2 4-2 4-2' }],
    ['line', { x1: '9', y1: '9', x2: '9.01', y2: '9' }],
    ['line', { x1: '15', y1: '9', x2: '15.01', y2: '9' }],
    ['path', { d: 'M16 5h6' }],
    ['path', { d: 'M19 2v6' }],
  ],
];

export default SmilePlus;
