import defaultAttributes from '../defaultAttributes';

const AlignHorizontalJustifyCenter = [
  'svg',
  defaultAttributes,
  [
    ['rect', { x: '2', y: '5', width: '6', height: '14', rx: '2' }],
    ['rect', { x: '16', y: '7', width: '6', height: '10', rx: '2' }],
    ['path', { d: 'M12 2v20' }],
  ],
];

export default AlignHorizontalJustifyCenter;
