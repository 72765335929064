import defaultAttributes from '../defaultAttributes';

const GraduationCap = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M22 10v6M2 10l10-5 10 5-10 5z' }],
    ['path', { d: 'M6 12v5c3 3 9 3 12 0v-5' }],
  ],
];

export default GraduationCap;
